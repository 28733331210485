module.exports = {
  defaultTitle: "Jacqueline Williams",
  logo: "https://gatsby-theme-portfolio.smakosh.com/favicon/favicon-512.png",
  author: "Jacqueline Williams",
  url: "https://jacquelinemwilliams.com",
  legalName: "Jacqueline Williams",
  defaultDescription: "I’m Jacqueline and I’m an Instructional Designer!",
  socialLinks: {
    twitter: "http://www.twitter.com/smakosh",
    github: "https://github.com/luigman",
    linkedin: "https://www.linkedin.com/in/jacquelinewilliams22/",
    instagram: "https://instagram.com/smakosh19",
    youtube: "https://www.youtube.com/user/smakoshthegamer",
    google: "https://plus.google.com/u/0/+IsmailSmakoshGhallou",
    telegram: "https://t.me/smakosh",
    stackOverflow: "https://stackoverflow.com/users/story/7396786"
  },
  googleAnalyticsID: "UA-88875900-4",
  themeColor: "#6b63ff",
  backgroundColor: "#6b63ff",
  social: {
    facebook: "appId",
    twitter: "@smakosh"
  },
  address: {
    city: "Seattle",
    region: "Washington",
    country: "USA"
    // zipCode: "ZipCode"
  },
  contact: {
    email: "jacqueline.williams22@gmail.com",
    phone: "phone number"
  },
  foundingDate: "2018",
  recaptcha_key: "6Lc7QogmAAAAACpazbiu7eSvFKoyyC5-AJKXwK0p"
};
